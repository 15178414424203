<template>
	<div>
		<div v-if="page" class="mobile-only page-header">
			<span>{{ page.header }}</span>
		</div>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link :to="localePath('', locale)" class="logo" />
						<div class="nav-content">
							<ul>
								<li>
									<div class="border mobile-only" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :exact="item.slug === 'index'" :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
									<div class="border mobile-only" />
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link :to="item.filename" @click="hideMobileNav">
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>
								<div class="border mobile-only" />
							</ul>
						</div>
						<div class="buttons">
							<div v-if="page" class="lang-nav desktop-only">
								<div
									v-for="item in page.langmenu"
									:key="`${item.filename}-${item.language}`"
									class="language"
								>
									<nuxt-link :to="`${item.filename}`" @click="hideMobileNav">
										<span>{{ $t(item.language) }}</span>
									</nuxt-link>
								</div>
							</div>
							<div class="mobile-only">
								<div class="page-title">
									<nuxt-link :to="localePath('', locale)"> AMI - caf&Eacute; manger </nuxt-link>
								</div>
							</div>
							<div class="nav-button-wrap">
								<a
									:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
									class="phone-button mobile-only"
								>
									<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								</a>
								<div class="menu-button button mobile-only" @click="toggleMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localePath = useLocalePath();

await fetchPage();
await fetchDefaults();

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.page-header {
	position: fixed;
	top: 70px;
	left: 5px;
	padding: 2px 6px;
	border: 1px solid #000;
	background: #fff;
	font-size: 20px;
	z-index: 99;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px 0 0;
	z-index: 1000;
	transition: background 0.3s ease-in-out;

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.lang-nav {
			display: flex;
			flex-flow: row nowrap;

			.language {
				margin: 0 5px;

				a {
					text-decoration: none;
					transition: border-color 0.3 ease-in-out;
					border-bottom: 3px solid rgba(0 0 0 / 0%);

					&.router-link-exact-active,
					&:hover {
						color: #000;
						border-color: #000;
					}
				}
			}
		}

		.button {
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			font-size: 32px;
		}

		.phone-button {
			margin: 0 28px 0 0;

			.icon {
				font-size: 22px;
			}
		}
	}
}

.menu-button {
	cursor: pointer;
	background: none;
	padding: 0;
	color: #000;
}

.logo {
	margin: 5px 0 0;
	padding: 0;
	background: url('~/assets/images/logo-ami-amsterdam-black-sideways.png') no-repeat center center;
	width: 50px;
	height: 120px;
	background-size: 50px;
}

.nav-button-wrap {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

.nav-content {
	margin: 0 40px;

	.nav-item a {
		color: #000;
		border-bottom: 3px solid rgba(0 0 0 / 0%);
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 30px;
		padding: 0 0 2px;
		letter-spacing: 1px;
		font-weight: 700;
		transition: border-color 0.3 ease-in-out;

		&.active,
		&:hover {
			color: #000;
			border-color: #000;
		}
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #6d6d6d;
		display: inline-block;
		max-width: 400px;
		color: #000;
		border-bottom: 3px solid rgba(0 0 0 / 0%);
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		transition: border-color 0.3 ease-in-out;

		.language {
			margin: 20px 0;
		}

		a {
			font-size: 15px;
			color: #fff;
			border-bottom: 3px solid rgba(0 0 0 / 0%);

			&.router-link-exact-active,
			&:hover {
				color: $cta-color;
				border-color: #000;
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: flex !important;
			justify-content: flex-end;
			border: none;
			padding: 0;
			margin: 0;

			a {
				color: #000;
			}

			.language {
				margin: 3px 4px 12px 24px;
			}
		}
	}

	.desktop-only {
		display: none !important;
	}

	.logo {
		position: fixed;
		bottom: 40px;
		left: 10px;
		z-index: 10;
	}

	.nav-bar {
		.buttons {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			background: #ebeceb;
			padding: 15px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;

			.icon {
				display: unset;
			}
		}

		.border {
			background: url('~/assets/images/dotted-line.png') repeat-x center left;
			width: 100%;
			margin: 0 auto;
			max-width: 1360px;
			height: 14px;
		}
	}

	.main-nav {
		top: 0;
		right: auto;

		.row {
			width: auto;

			.column12 {
				width: auto;
			}
		}

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 0;

					span {
						width: 100%;
						text-align: right;
						display: inline-block;

						a {
							margin: 0;
							padding: 5px 2px;
							font-size: 16px;
							border: none;
							width: 100%;
							display: inline-block;
							transition: background 0.3s ease-in-out;

							&:hover,
							&.active {
								background: #ebd4cf;
							}
						}
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 62px 0 auto;
			background: #fff;
			text-align: right;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
			padding: 0 15px;
		}
	}
}
</style>
